import React from "react"
import useDatoMicrocopy from "../../../../hooks/useDatoMicrocopy"
import { DatoLocale } from "../../../../types"
import { OptionProps } from "../../../atoms/form/Option"
import Select from "../../../atoms/form/Select"

type ContactCategorySelectProps = {
  onChange?: React.ChangeEventHandler<HTMLSelectElement>
  locale: DatoLocale
}

const optValues = [
  "productFeedback",
  "productComplaint",
  "support",
  "spareParts",
  "marketing",
  "logistics",
  "sales",
  "r&d",
  "other",
] as const

/**
 * Dropdown for contact forms. Renders a `<select name="category">` element.
 * Data is supposed to end up in Harvia CRM Ticket field `cf_1752`
 */
const ContactCategorySelect: React.FC<ContactCategorySelectProps> = props => {
  const { locale = "en" } = props
  const t = useDatoMicrocopy("contactCategorySelect", locale)

  const options: OptionProps[] = optValues.map(opt => ({ value: opt, label: t(opt) }))
  options.unshift({ value: "", label: t("preselect", "Choose your topic...") })

  return (
    <Select
      name="category"
      label={t("label", "What your messsage concerns")}
      options={options}
      required={true}
      attrs={{ onChange: props.onChange }}
    />
  )
}

export default ContactCategorySelect
