import React, { useContext, useState } from "react"
import { PageContext } from "../../../context/PageContext"
import { CrmFieldErrors, fieldError } from "../../../hooks/useCrmApi"
import useDatoMicrocopy from "../../../hooks/useDatoMicrocopy"
import Button from "../../atoms/Button"
import Checkbox from "../../atoms/form/Checkbox"
import Fieldset from "../../atoms/form/Fieldset"
import Input from "../../atoms/form/Input"
import { OptionProps } from "../../atoms/form/Option"
import Select from "../../atoms/form/Select"
import TextArea from "../../atoms/form/TextArea"
import LoadingSpinner from "../../atoms/LoadingSpinner"
import FileInput from "../../molecules/form/FileInput"
import { clientProductAges } from "./ContactProductSupport"
import ContactCategorySelect from "./subcomponents/ContactCategorySelect"
import CountrySelect from "./subcomponents/CountrySelect"
import UserContactFieldset from "./subcomponents/UserContactFieldset"
// import * as styles from "./ContactB2C.module.scss"

type ContactB2CProps = {
  formRef: React.LegacyRef<HTMLFormElement>
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  onFilesChange: (files: File[]) => void
  loading: boolean
  errors?: CrmFieldErrors
}

const ContactB2C = (props: ContactB2CProps) => {
  const { locale } = useContext(PageContext)
  const t = useDatoMicrocopy("formContact", locale)
  const [showProductfields, setShowProductfields] = useState(true)

  const ageOptions: OptionProps[] = clientProductAges.map(opt => ({
    value: opt,
    label: t(opt, opt, "productAgeSelect"),
  }))
  ageOptions.unshift({ value: "", label: t("preselect", "Choose...", "formCommon") })

  const defaultErrorMsg = t("default", "Invalid value", "formErrors")

  /**
   * Feedback categories related to products.
   * When one of these values is selected in ProductFeedbackSelect dropdown,
   * product information fieldset is shown.
   */
  const productCategories = ["productFeedback", "productComplaint", "support", "spareParts"]

  const onCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const category = e.currentTarget.value
    setShowProductfields(productCategories.includes(category))
  }

  const getErrorMsg = (field: string): string | undefined => {
    const error = fieldError(props.errors, field)
    if (error) {
      return error.type ? t(error.type, defaultErrorMsg, "formErrors") : defaultErrorMsg
    }
    return
  }

  return (
    <form ref={props.formRef} onSubmit={props.onSubmit} autoComplete="on" name="contact-b2c">
      <Fieldset legend="">
        <ContactCategorySelect onChange={onCategoryChange} locale={locale} />
        <TextArea
          name="message"
          label={t("message.label", "Your message", "formCommon")}
          placeholder={t("message.placeholder", "Type your message here", "formCommon")}
          errorMsg={getErrorMsg("message")}
        />
        <FileInput
          name="files"
          label="Drag &amp; Drop your files here"
          buttonLabel="Select files"
          onFilesChange={props.onFilesChange}
          previewTitle={t("files.preview.title", "Selected files", "formCommon")}
          previewRemoveLabel={t("files.preview.buttonLabel", "Remove", "formCommon")}
          required={false}
          inputAttrs={{ accept: "image/*" }}
        />
      </Fieldset>
      {showProductfields && (
        <Fieldset legend="Product information">
          <Input
            name="productType"
            label={t("productType.label", "Product make and type")}
            helpText={
              <a href={t("productType.helpUrl", "/en/contact/where-to-find-harvia-serial-number")} target="_blank">
                {t("productType.helpText", "How can I tell the model of my heater or stove?")}
              </a>
            }
            required={false}
            errorMsg={getErrorMsg("productType")}
          />
          <Select
            name="productAge"
            label="Product age"
            options={ageOptions}
            required={false}
            errorMsg={getErrorMsg("productAge")}
          />
          <Input
            name="productSerial"
            label="Product serial number"
            required={false}
            helpText={
              <a href="/en/contact/where-to-find-harvia-serial-number" target="_blank">
                Where to find serial numbers on Harvia products
              </a>
            }
            errorMsg={getErrorMsg("productSerial")}
          />
        </Fieldset>
      )}

      <UserContactFieldset errors={props.errors}>
        <CountrySelect
          name="country"
          label={t("country.label", "Country")}
          preselectLabel={`${t("country.label", "Country")}...`}
          required={true}
          errorMsg={getErrorMsg("country")}
        />
      </UserContactFieldset>
      <Checkbox
        name="consent-pii-data"
        label={
          <span>
            {t(
              "consent-pii-data.label",
              "I agree to the storage and processing of my personal data (required).",
              "formCommon"
            )}{" "}
            <a href={t("consent-pii-data.labelUrlPath", "/en/register-description", "formCommon")} target="_blank">
              {t("consent-pii-data.labelUrlText", "Register description", "formCommon")}
            </a>
          </span>
        }
        required={true}
        errorMsg={getErrorMsg("consent-pii-data")}
      />

      <Button label={t("sendButton.label", "Approve & send", "formCommon")} type="submit" disabled={props.loading} />
      {props.loading && <LoadingSpinner loading={props.loading} />}
    </form>
  )
}

export default ContactB2C
